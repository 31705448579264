 /* .main {
  text-align: center;
  
} */

/* .header{
  border: 1px solid black;
  background-color:rgb(255, 255, 255);
  height: 100px;
  width: 300px;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 20px;
  text-align: center;
  border-radius: 3rem;
  
}

.header h1{

  padding-top: 10px;
  margin: auto;
  font-family: Georgia, 'Times New Roman', Times, serif;
} */



/* .temel {
  margin: auto;
  margin-top: 50px;
  height: 1000px;
  width: 800px ;
  
  background-color:rgb(246, 249, 252);
}

.video{
  margin-top: 10px;
}

.sonuc{
  
  font-weight: bolder;
} */ 

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

:root{
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%),hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --cardBG: rgb(225, 225, 235);
}

b{
  color: hsl(199, 100%, 33%);
}

a{
    text-decoration: none;
}

li{
    list-style: none;
}

.section{
    padding: 4rem 0 2rem;
 }

.container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.icon{
    font-size: 2rem;
    cursor: pointer;
}

.flex{
    display: flex;
    align-items: center;
}

.grid{
    display: grid;
    align-items: center;
}

.btn{
    padding: .6rem 1.5rem;
    background: var(--gradientColor);
    border: none;
    outline: none;
    border-radius: 3rem;
    cursor: pointer;
    color:#fff
}



.btn a{
 color: var(--whiteColor);
 font-weight: 500;
}

.btn:hover{
    background: var(--SecondaryColor);
}

.btn-map{
    position: absolute;
    right: 10px;
    bottom: 20px;
    padding: .6rem 1.5rem;
    background: var(--gradientColor);
    border: none;
    outline: none;
    border-radius: 3rem;
    cursor: pointer;
    color: white;
}

.btn-filter{
    background-color:  hsl(187, 85%, 43%);
    border:none;
    border-radius: 3rem;
    cursor: pointer;
}
.type-filters{
    right:0px ;
}
.type-filters-btns{
    background: var(--gradientColor);
    border: none;
    border-radius: 3rem;
    cursor: pointer;
    color: white;
    margin-right: 1.5rem;
    padding: 0.4rem;
}

 video{
width: 100%;
height: auto;
}

input{
    font-size: 100%;
}

body{
    background: var(--bodyColor);
}

::selection{
    background: var(--PrimaryColor);
    color: var(--whiteColor);
}

::-webkit-scrollbar{
    width: 10px;
    background: var(--whiteColorDeam);
}
::-webkit-scrollbar-thumb{
    background: var(--SecondaryColor);
    border-radius: 10px;
}


@media screen and (min-width: 1024px) {
    .container{
        width: 80% !important;
        margin: auto !important;
    }

    .section{
        padding: 6rem 0 4rem;
     }
}

@media screen and (min-width: 2560px){
    body{
        zoom: 1.7;
    }
}

.sorguekrani{
  position: relative;
  
  
}

.icerik{
  text-align: center;
  margin:auto
}

.icerik2{
  text-align: center;
  margin: auto;
  margin-top: 50px;
 
  width: 800px ;
  
 
}

.camera{
  
  width: 70%;
  height: 70%;
  margin: auto;
}

.sonuc{
  margin: 0;
  padding: 0;
  text-align: center;
}

.sonuc h3{
  margin: auto;
  font-weight: bolder;
}

/* table {
   border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  text-align: center;
  margin: auto;
  
} 


td, th {
  border: 1px solid #999;
  padding: 20px;
}
th {
  background:hsl(0, 0%, 94%);
  color:black;
  border-radius: 0;
  position: sticky;
  top: 0;
  padding: 10px;
}
.primary{
  background-color: #000000
}

tfoot > tr  {
  background: black;
  color: white;
}
tbody > tr:hover {
  background-color: #ffc107;
} */



table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

.button {
  display: inline-block;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  background-image: linear-gradient(to right, #45b0da 50%, #ffce00 50%, #e84274);
  background-size: 200%;
  background-position: 0%;
  transition: 0.4s;
  color:#000;
  font-weight: 700;
  cursor: pointer;
}

.input {
    margin: auto;
    display: block;
    width:120px;
    padding: 10px 15px;
    background-color: #f8f8f8f8;
    border-radius: 8px;
    transition: 0.4s;
    margin-bottom: 10px;
    
}
i{
  color: red;
}

* {
  box-sizing: border-box;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}

html {
  font-family: "Lucida Sans", sans-serif;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #33b5e5;
  color: #ffffff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.menu li:hover {
  background-color: #0099cc;
}

.aside {
 
  padding: 15px;
  color: black;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
}

