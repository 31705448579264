* {
    margin: 0;
    box-sizing: border-box;

}


.page {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.cover {
    
    background-color: white;
    width: 30em;
    height: 35em;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156,156);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    
   
}

input {
    border: none;
    background-color: rgb(229, 226, 226);
    height: 4em;
    width:50%;
    border-radius: 0.25em;
    text-align: center;
    padding: 2em;
}

.login-btn {
    margin: 0;
    width:40%;
    height: 3em;
    background-color: rgb(32,177,255);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    border-radius: 0.25em;
    cursor: pointer;
}

.login-btn:hover {
    transform: scale(1.25);
}



input:focus {
    outline-color:(rgb(32, 177,255)) ;
}


.text {
    position: relative;
    bottom: -2em;
}


.hide {
    position: absolute;
    transform:scale(0);
}

.login-popup{
    /* position: relative;
    height: 15em;
    width:25em;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    margin: 0;
    padding: 0;
    text-align: center;
    border-radius: 0.25em;
    background-color:none;
    color: red;
    bottom: 0;
    transform: scale(1);
    transform: 1.5s;

}

.sonucv2{
    margin: 0;
  padding: 0;
  text-align: center;
  border-radius: 1em;
  width: 100%;
  
  margin-top: 10%;
  
}
